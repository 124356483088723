import React, { useState, Component, useEffect ,useContext} from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NotAuth from "./NotAuth.js";
//import { MantineProvider } from '@mantine/core';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthProvider ,AuthContext} from "./authcontext.js"; 

import { Login, Home, Footer } from "./components";
import Contactus from "./components/Contactus";
import MdmTicketingHome from "./components/mdm-ticketing/MdmTicketingHome";
// import { RoleContextProvider } from "../context/RoleContext";
// import { FormPageContextProvider } from "../context/formPageContext";
import Tickets from "./components/mdm-ticketing/Tickets";
//import AxiosApi from 'AxiosApi';
import AxiosApi from "./Api.js";
import NavBar from "./components/Navbar";
import FooterComponent from "./components/common/FooterComponent";

//retpack
import RequestFormRetpack from "./components/retPack/Components/requestform";
import ChangePassword from "./components/retPack/Components/changepassword.jsx";
import DashbordRetpack from "./components/retPack/Components/dashbord.jsx";
import ReportDashboard from "./components/retPack/Components/reportDashboard.jsx";
import Resetpassword from "./components/retPack/Components/resetpassword.jsx";
import Userregistration from "./components/retPack/Components/userregistration.jsx";
import Navigation from "./components/retPack/Components/Navigation.jsx";
// import NavBarR from './retPack/Components/navbar.jsx'
// import NavBar from "./Navbar";
//retpack

// OOC
import "./components/ooc/App.css";
import RequestForm from "./components/ooc/oocComponent/reqForm";
import Dashbord from "./components/ooc/oocComponent/dashbord";
import Navbar_OOC from "./components/ooc/oocComponent/navbar";
import Uploader from "./components/ooc/oocComponent/fileUpload";
import Admin from "./components/ooc/oocComponent/createAdmin";
import Archives from "./components/ooc/oocComponent/Archive";
// import Footer from "./ooc/oocComponent/footer";
import UploadPage from "./components/ooc/oocComponent/uploadPage";
// end of OOC

//CQPortalComponents
import HomeComponent from "./components/cq-portal/HomeComponent";
import AddComplaint2 from "./components/cq-portal/AddCommplaint2";
import ComplaintPag2 from "./components/cq-portal/ComplaintPag2"; // needs to be uncomment
import WizardExample from "./components/cq-portal/ComplaintPage3.js";
import Allcomplaints from "./components/cq-portal/All Complaints/Complaints.js";
import Signup from "./components/rbac/Signup"; // ANAS
import NewPassword from "./components/rbac/NewPassword";
import ForgotPassword from "./components/rbac/forgotPassword"; //ANAS
import Header from "./components/rbac/common/Header";
import NotificationsTable from "./components/cq-portal/NotificationsTable";
import { MantineProvider } from "@mantine/core";
import "./assets/styles/App.css";
import { roles, updateRoles } from "./Constants.js";
import { loginRequest, snowBackendRequest } from "./utils/authConfig.js";


const Pages = () => {
  const isAuthenticated = useIsAuthenticated();
  const [name, changeName] = useState("");
  const [email, changeEmail] = useState("");
  const { accounts, instance } = useMsal();
  const { authState, login, logout } = useContext(AuthContext);
  console.log(authState)
  const role=authState

  const [isLoggedInViaInput, setisLoggedInViaInput] = useState(false);
  const changeLoggedInViaInput = (bool) => {
    setisLoggedInViaInput(bool);
  };
  const [username, setusername] = useState();
  const [password, setpassword] = useState();

  const [state, setState] = useState({
    ooc_user_role: roles.ooc_user_role,
    email: roles.username,
    username: roles.name,
    isReady: false,
  });

  useEffect(() => {
    if (accounts.length > 0) {
      console.log("starting below");
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          // console.log(response);
          //test
          updateRoles({ username: accounts[0].username });
          updateRoles({ emailid: accounts[0].username });
          updateRoles({ Authorization: response.idToken });
          const email=accounts[0].username;
         
          localStorage.setItem("Authorization", response.idToken);
          localStorage.setItem("email", email);
          AxiosApi.post(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getallrole`,
            { email: email }
          ).then((res) => {
            console.log(res);
            updateRoles({ role: res.data?.cq });
            updateRoles({ ooc_user_role: res.data?.ooc });
            updateRoles({ userRole: res.data?.retpak });
            const payload = {
              username: email,
              cq:res.data?.cq , // Replace with actual data from API
              ooc:res.data?.ooc,
              retpack: res.data?.retpak,
              isAuthenticate:true,
              email:email
            };
            //login(payload)
    
        })
      })
  }
  
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("Authorization");
    updateRoles({ username: email });
    changeEmail(email);
    if (email && token) {
      console.log("Starting with stored email and token");

      // First API call to get user role
      AxiosApi.post(
        `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/api/getallrole`,
        { email: email }
      ).then((res) => {
        console.log(res);
        updateRoles({ role: res.data?.cq });
        updateRoles({ ooc_user_role: res.data?.ooc });
        updateRoles({ userRole: res.data?.retpak });
        const payload = {
          username: email,
          cq:res.data?.cq , // Replace with actual data from API
          ooc:res.data?.ooc,
          retpack: res.data?.retpak,
          isAuthenticate:true,
          email:email
        };
        login(payload)


        setTimeout(() => {
          console.log(roles);
        }, 3000);
      });
    }
  
  }, [accounts]);
  const isAuth = () => {
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("Authorization");
    return email?.length > 0 && token?.length > 0;
  };
  const isOocAuthorized = (role, allowedRoles = ["1", "2", "3", "4"]) => {
    return allowedRoles.includes(String(role));
  };
  const hasValidRole = (role, allowedRoles = []) => {
    return allowedRoles.includes(String(role));
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/forgotPassword"
          element={
            <React.Fragment>
              <Header />
              <ForgotPassword />
            </React.Fragment>
          }
        />

        <Route
          path="/newPassword"
          element={
            <React.Fragment>
              <Header />
              <NewPassword />
            </React.Fragment>
          }
        />

        <Route
          path="/"
          element={<Home loggedInViaInput={isLoggedInViaInput} />}
        />
        <Route
          path="/register"
          element={
            <React.Fragment>
              <Header />
              <Signup />
            </React.Fragment>
          }
        />

        <Route
          path="/myTickets"
          element={
            roles.username?.length > 0 ? <Tickets /> : <Navigate to="/login" />
          }
        />

        <Route
          path="/contactus"
          element={
            <React.Fragment>
              <NavBar />
              <Contactus />
            </React.Fragment>
          }
        />

        <Route
          path="/mdm"
          element={
            roles.username?.length > 0 ? (
              <MdmTicketingHome />
            ) : (
              // <h1>Work is on Progress. Sorry for inconvenience.</h1>
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/login"
          element={
            <Login
              username={username}
              setusername={setusername}
              password={password}
              setpassword={setpassword}
              loggedInViaInputFn={changeLoggedInViaInput}
              loggedInViaInput={isLoggedInViaInput}
              setisLoggedInViaInput={setisLoggedInViaInput}
            />
          }
        />
        {/*CQ Portal routes*/}
        <Route
        path="/cq-portal-home"
        element={
          isAuth() && role.cq !== 'Employee' ? (
            <HomeComponent />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      {/* Route: /complaints */}
      <Route
        path="/complaints"
        element={
          isAuth() && role.cq !== 'Employee' ? (
            <Allcomplaints />
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      {/* Route: /add-complaint */}
      <Route
        path="/add-complaint"
        element={
          isAuth() && (role.cq != 'Manager' || role.cq != 'ABI_CQ_SPOC') ? (
            <AddComplaint2 />
          ) : (
            <h1>You are not authorized to view this</h1>
          )
        }
      />

      {/* Route: /complaint/num/:ackno */}
      <Route
        path="/complaint/num/:ackno"
        element={
          isAuth() && !(role.cq === 'External_Customer' || role.cq === 'Internal_Customer' || role.cq === 'Employee') ? (
            <ComplaintPag2 />
          ) : (
            <h1>You are not authorized to view this page</h1>
          )
        }
      />

      {/* Route: /complaint-2/num/:ackno */}
      <Route
        path="/complaint-2/num/:ackno"
        element={
          isAuth() && !(role.cq === 'ABI_CQ_SPOC' || role.cq === 'ABI_CQTeam' || role.cq === 'Employee') ? (
            <WizardExample />
          ) : (
            <h1>You are not authorized to view this page</h1>
          )
        }
      />

      {/* Route: /notificationspage */}
      <Route
        path="/notificationspage"
        element={
          isAuth() ? (
            <NotificationsTable />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
       <Route
  path="/one-order-change-homepage"
  element={
    isAuth() && (role.ooc == 1 || role.ooc == 2 || role.ooc == 3 || role.ooc == 4) ? (
      <React.Fragment>
        {console.log("inside", role.ooc)}
        <Navbar_OOC name={role.username} userRole={role.ooc} email={role.email} />
        <RequestForm email={role.email} />
      </React.Fragment>
    ) : isAuth() ? (
      <NotAuth />
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/upload2"
  element={
    isAuth() && (role.ooc == 1 || role.ooc == 2 || role.ooc == 3 || role.ooc == 4) ? (
      <React.Fragment>
        <Navbar_OOC name={role.username} userRole={role.ooc} email={role.email} />
        <UploadPage email={role.username} />
      </React.Fragment>
    ) : isAuth() ? (
      <NotAuth />
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/dashboard"
  element={
    isAuth() && (role.ooc == 1 || role.ooc == 2 || role.ooc == 3 || role.ooc == 4) ? (
      <React.Fragment>
        <Navbar_OOC name={role.username} userRole={role.ooc} email={role.email} />
        <Dashbord email={role.username} userRole={role.ooc} />
      </React.Fragment>
    ) : isAuth() ? (
      <NotAuth />
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/upload"
  element={
    isAuth() && (role.ooc == 1 || role.ooc == 2 || role.ooc == 3 || role.ooc == 4) ? (
      <React.Fragment>
        <Navbar_OOC name={role.username} userRole={role.ooc} email={role.email} />
        <Uploader email={role.username} />
      </React.Fragment>
    ) : isAuth() ? (
      <NotAuth />
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/userMgmt"
  element={
    isAuth() && (role.ooc == 2 || role.ooc == 3) ? (
      <React.Fragment>
        <Navbar_OOC name={role.username} userRole={role.ooc} email={role.email} />
        {role.ooc == 2 || role.ooc == 3 ? (
          <Admin />
        ) : (
          <RequestForm email={role.username} />
        )}
      </React.Fragment>
    ) : isAuth() ? (
      <NotAuth />
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/archive"
  element={
    isAuth() && (role.ooc == 2 || role.ooc == 3) ? (
      <React.Fragment>
        <Navbar_OOC name={role.username} userRole={role.ooc} email={role.email} />
        {role.ooc == 2 || role.ooc == 3 ? (
          <Archives />
        ) : (
          <RequestForm email={role.username} />
        )}
      </React.Fragment>
    ) : isAuth() ? (
      <NotAuth />
    ) : (
      <Navigate to="/login" />
    )
  }
/>

        


        
       
        {/* retpack */}
        <Route
  path="/retPack"
  element={
    isAuth() ? (
      [1, 2, 3, 4].includes(role.retpack) ? (
        <React.Fragment>
          <NavBar />
          <Navigation />
          <RequestFormRetpack
            userRole={role.retpack}
            emailid={role.username}
            isAuthenticate={true}
            isReady={true}
          />
        </React.Fragment>
      ) : (
        <NotAuth />
      )
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/retPack/requestform"
  element={
    isAuth() ? (
      [1, 2, 3, 4].includes(role.retpack) ? (
        <React.Fragment>
          <NavBar />
          <Navigation />
          <RequestFormRetpack
            userRole={role.retpack}
            emailid={role.username}
            isAuthenticate={role.isAuthenticate}
            isReady={true}
          />
        </React.Fragment>
      ) : (
        <NotAuth />
      )
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/retPack/reports"
  element={
    isAuth() ? (
      [1, 2, 3, 4].includes(role.retpack) ? (
        <React.Fragment>
          <NavBar />
          <Navigation />
          <ReportDashboard
            userRole={role.retpack}
            emailid={role.username}
            isAuthenticate={role.isAuthenticate}
            isReady={role.isReady}
          />
        </React.Fragment>
      ) : (
        <NotAuth />
      )
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/Registration"
  element={
    isAuth() ? (
      [1, 3].includes(role.retpack) ? (
        <React.Fragment>
          <NavBar />
          <Navigation />
          <Userregistration />
        </React.Fragment>
      ) : (
        <NotAuth />
      )
    ) : (
      <Navigate to="/login" />
    )
  }
/>

<Route
  path="/retPack/Dashboard"
  element={
    isAuth() ? (
      [1, 3].includes(role.retpack) ? (
        <React.Fragment>
          <NavBar />
          <Navigation />
          <DashbordRetpack />
        </React.Fragment>
      ) : (
        <NotAuth />
      )
    ) : (
      <Navigate to="/login" />
    )
  }
/>


        <Route path="*" element={<h1>Error 404 Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <div className="App">
      <MantineProvider>
        <MsalProvider instance={msalInstance}>
          <AuthProvider>
          {navigator.onLine && <Pages />}
          </AuthProvider>
          {/* {!navigator.onLine && <NoInternet />} */}
        </MsalProvider>
      </MantineProvider>
    </div>
  );
}

export default App;
