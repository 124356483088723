import React, { useState, useEffect,useContext } from 'react';
import { Card, Table } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import AxiosApi from '../../Api';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NavbarComponent from './NavbarComponent.js';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import Container from 'react-bootstrap/Container';
// import FooterComponent from './FooterComponent';
import ReactDOM from 'react-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ReactStars from 'react-rating-stars-component';
import { Link, useNavigate } from 'react-router-dom';
import { roles } from '../../Constants.js';
import { Skeleton } from '@mantine/core';
import './CQ.module.css';
import './Navbar.css';
import { AuthContext } from '../../authcontext.js';
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;


const HomeComponent = () => {
  const { authState, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [rate, setrate] = useState(0);
  const [players, setPlayers] = useState([]);
  const [total, settotal] = useState(0);
  const [open, setopen] = useState(0);
  const [accepted, setaccepted] = useState(0);
  const [newtoday, setnewtoday] = useState(0);
  const [closedtoday, setclosedtoday] = useState(0);
  const [creditpending, setcreditpending] = useState(0);
  const [loading, setLoading] = useState(false);
 

 

  const columns = [
    {
      dataField: 'acknowledgementNumber',
      text: 'Acknowledgement No.',
      formatter: linkFormatter,
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
        
      },
     
      
    },
    {
      dataField: 'complaintNumber',
      text: 'Complaint No.',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'companyName',
      text: 'Company',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'defectType',
      text: 'Defect Type',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'salesOrder',
      text: 'Sales Order',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'PO',
      text: 'Customer PO',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'Container',
      text: 'Container',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'uploaded',
      text: 'Date of Upload',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'registeredBy',
      text: 'Assigned To',
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
    {
      dataField: 'Rating',
      text: 'Rating',
      align: 'center',
      formatter: (cell, row) => {
        const parseBoolean = (str) => {
          if (str == 'Yes') {
            return false;
          }
          if (str == 'Yes') {
            return true;
          }
        };
        if (row.status == 'Closed') {
          const ratingChanged = (newRating) => {
            for (var i = 0; i < players.length; i++) {
              if (players[i].acknowledgementNumber === row.acknowledgementNumber) {
                players[i].Rating = newRating;
                players[i].isRated = 'Yes';
                console.log('This is the loop');
                console.log(newRating);
                console.log('Yes');
              }
            }
            setPlayers(players)
            row.Rating = String(newRating);
            console.log(row.Rating);
            console.log(newRating);
            row.isRated = 'Yes';
            console.log(row.isRated);
            console.log(row.acknowledgementNumber);
            console.log(row.isRated);
            console.log(row.Rating);
            setPlayers(players)
            AxiosApi.post(process.env.REACT_APP_API_ROOT_URL_PREFIX + '/save/api/rating', {
              ackno: row.acknowledgementNumber,
              rating: row.Rating,
              israted: row.isRated,
            }).then((response) => {
              console.log(response?.data);
              setrate(rate + 1)
            });
          };
          if (row)
            if (
              (authState.cq) == 'External_Customer' ||
              (authState.cq) == 'Internal_Customer'
            ) {
              return (
                <div style={{ alignContent: 'center' }}>
                  <ReactStars
                    value={parseInt(row.Rating)}
                    count={5}
                    edit={parseBoolean(row.isRated)}
                    onChange={ratingChanged}
                    size={24}
                    activeColor="#ffd700"
                  />
                </div>
              );
            }
            else {
              return (
                <div style={{ alignContent: 'center' }}>
                  {row.Rating}
                </div>
              )
            }
        }
      },
      headerStyle: {
        backgroundColor: '#f1c40f',
        color: '#fff',
        fontWeight: '500',
      },
    },
  ];
  var username,
    role,
    userID,
    soldto = [];
  useEffect(() => {
    // GET request using AxiosApi inside useEffect React hook
    username = (authState.email);
    role = (authState.cq);
    console.log('Getting data');
    AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchUserId?email=${username}`)
      .then((response) => {
        userID = response?.data?.recordset[0]?.userID;
        return AxiosApi.get(`${process.env.REACT_APP_API_ROOT_URL_PREFIX}/fetchSoldTo?userID=${userID}`);
      })
      .then((response) => {
        var isLength = response?.data?.recordset?.length == 0 ? 1 : response?.data?.recordset?.length;
        for (var i = 0; i < isLength; i++) {
          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/database/api/v1/comp/1?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              if (role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager') {
                setPlayers(response?.data);
              } else setPlayers((players) => [...players, ...response?.data]);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/database/api/v1/comp/1?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          )
            .then(function (response) {
              // handle success
              console.log(response?.data);
              if (role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager') {
                setPlayers(response?.data);
              } else setPlayers((players) => [...players, ...response?.data]);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/dashboard/api/total-complaints?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          )
            .then(function (response) {
              // handle success

              role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager'
                ? settotal(response?.data[0]['totalcount'])
                : settotal((total) => total + response?.data[0]['totalcount']);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });

          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/dashboard/api/total-open?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          ).then(function (response) {
            // handle success
            console.log(JSON.stringify(response?.data));
            role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager'
              ? setopen(response?.data[0]['totalopen'])
              : setopen((open) => open + response?.data[0]['totalopen']);
          });

          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/dashboard/api/total-accepted?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          )
            .then(function (response) {
              // handle success
              console.log(JSON.stringify(response?.data));
              role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager'
                ? setaccepted(response?.data[0]['totalaccepted'])
                : setaccepted((accepted) => accepted + response?.data[0]['totalaccepted']);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/dashboard/api/new-today?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          )
            .then(function (response) {
              // handle success
              console.log(JSON.stringify(response?.data));
              role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager'
                ? setnewtoday(response?.data[0]['newtoday'])
                : setnewtoday((newtoday) => newtoday + response?.data[0]['newtoday']);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
          AxiosApi.get(
            `${process.env.REACT_APP_API_ROOT_URL_PREFIX}/dashboard/api/credit-notes-pending?email=${username}&role=${role}&soldTo=${response?.data?.recordset?.length > 0 ? response?.data.recordset[i]['parentAccountNumber'] : ""}`,
          )
            .then(function (response) {
              // handle success
              console.log(JSON.stringify(response?.data));
              role === 'ABI_CQ_SPOC' || role === 'ABI_CQTeam' || role === 'Manager'
                ? setcreditpending(response?.data[0]['creditpending'])
                : setcreditpending(
                  (creditpending) => creditpending + response?.data[0]['creditpending'],
                );
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
        }
      });
  }, [rate,authState]);
  function linkFormatter(cell, row, rowIndex, extraData) {
    if (cell) {
      if (
        (authState.cq) == 'External_Customer' ||
        (authState.cq) == 'Internal_Customer'
      ) {
        const url = '/complaint-2/num/' + cell;

        return <Link exact to={url}>{cell}</Link>
      } else {
        const url = '/complaint/num/' + cell;
        return <Link exact to={url}>{cell}</Link>
        // this is the homepage of cq portal
      }
    }
    return <span></span>;
  }

  // useEffect(() => {
  //   // GET request using AxiosApi inside useEffect React hook
  //   console.log('Getting data');
  //   let newDate = new Date();
  //   let date = newDate.getDate();
  //   AxiosApi.get('/dashboard/api/new-today')
  //     .then(function (response) {
  //       // handle success
  //       console.log(JSON.stringify(response?.data));
  //       setnewtoday(JSON.stringify(response?.data[0]['newtoday']));
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // }, []);

  // useEffect(() => {
  //   // GET request using AxiosApi inside useEffect React hook
  //   console.log('Getting data');
  //   AxiosApi.get('/dashboard/api/closed-today')
  //     .then(function (response) {
  //       // handle success
  //       console.log(JSON.stringify(response?.data));
  //       setclosedtoday(JSON.stringify(response?.data[0]['closedtoday']));
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // }, []);
  // useEffect(() => {
  //   // GET request using AxiosApi inside useEffect React hook
  //   console.log('Getting data');
  // }, []);

  const rowStyle = { fontWeight: '600' };
  const cardInfo = [
    { title: '', text: 'Total Complaints' },
    { title: '1', text: 'Total Open' },
    { title: '0', text: 'Total Accepted' },
    { title: '0', text: 'New Today' },
    { title: '0', text: 'Closed Today' },
    { title: '0', text: 'Credit Notes Pending' },
  ];

  return (
    <div>
      {!loading ? (
        <div className="background" style={{ marginBottom: '5rem', marginRight: '20px' }}>
          <NavbarComponent />
          <Container style={{ marginBottom: '60px' }}>
            <div className="page-header ml-4">
              <h1 className="page-title">Customer Claims Portal - Dashboard</h1>
            </div>

            {/* anas */}
            <div className="row d-flex justify-content-center align-items-center">

              <CustomCard text="Total Complaints" value={total} />
              <CustomCard text="Total Open" value={open} />
              <CustomCard text="Total Accepted" value={accepted} />
              <CustomCard text="New Today" value={newtoday} />
              <CustomCard text="Closed Today" value={closedtoday} />
              <CustomCard text="Credit Notes Pending" value={creditpending} />

            </div>
            

            <div>
              <ToolkitProvider
                keyField="name"
                data={players}
                columns={columns}
                
                exportCSV={{ onlyExportFiltered: true, exportAll: false }}
                search
              >
                {(props) => (
                  <div>
                    <hr />
                    <h5 className="recent-complaints-title">Latest Complaints</h5>
                    <div className="text-right" style={{ marginBottom: '0.5rem' }}>
                      <ExportCSVButton {...props.csvProps} className="text-right btn-warning text-white">
                        Export to CSV
                      </ExportCSVButton>
                      <div className="text-center">
                        <SearchBar {...props.searchProps} />
                        <ClearSearchButton className="btn ml-1 btn-warning mb-2 text-white" {...props.searchProps} />
                      </div>
                    </div>
                    <div className="table-background expanded-container">
                      <BootstrapTable
                        style={({ display: 'table' }, { marginBottom: '60px' })}
                        {...props.baseProps}
                        rowStyle={rowStyle}
                       
                        striped
                        hover
                        condensed
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Container>
        </div>
      ) : (
        <ReactBootstrap.Spinner animation="border" />
      )}
    </div>
  );
};

const CustomCard = ({ text, value }) => {
  return (

    text && value != null ?
      <div className='col-md-3 custom_card py-4 m-2'>
        <h1 className=''>{value}</h1>
        <h6 className='text-uppercase'>{text}</h6>
      </div>
      :
      <div className='col-md-3 custom_card py-4 m-2'>
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
      </div>
  )
}


export default HomeComponent;
