import React, { createContext, useState } from "react";

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    username: "",
    email:  "", // Added email field
    ooc:  "", // Updated userRole field to ooc
    cq:  "", // Updated to cq
    retpack:  "", // Added retpack field
    isAuthenticate: false,
  });

  // Method to log out the user
  const logout = () => {
    localStorage.clear();
    setAuthState({
      username: "",
      email: "",
      ooc: "",
      cq: "",
      retpack: "",
      isAuthenticate: false,
    });
  };

  // Method to update the auth state
  const login = (data) => {
    // Save to localStorage for persistence


    setAuthState({
      username: data.username,
      email: data.email,
      ooc: data.ooc,
      cq: data.cq,
      retpack: data.retpack,
      isAuthenticate: true,
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
